import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
// import Image from "gatsby-image"
import SEO from "../components/seo"
import TopMenu from "../components/topmenu"

const IndexPageTemplate = ({ data, pageContext }) => {
  // console.log("IndexTemplate: ", data);
  const { frontmatter: page } = data.page;
  const slug = data.page.fields.slug;
  const heroPics = page.pictures;

  let [activeImg, setActiveImg] = useState(0);
  let [prevActiveImg, setPrevActiveImg] = useState();
  useEffect(() => {
    const timer = setInterval(scrollRight, 4000);
    return () => {
      clearInterval(timer)
    }
  })
  function scrollRight() {
    if (heroPics.length - 1 === activeImg) {
      setPrevActiveImg(activeImg);
      setActiveImg(0);
    } else {
      setPrevActiveImg(activeImg);
      setActiveImg(activeImg + 1);
    }
  }
  return (
    <section className="tar-page tar-hero color-white">
      <SEO
        title="Home"
        description={page.description}
        keywords={[`photo`, `photographer`, `Taranukhina`, `Elena`,`valokuvaaja`,`Tampere`]}
      />
      {
        heroPics.map((pic, index) => {
          let classes = "tar-hero-img" + ((activeImg === index || prevActiveImg === index) ? " tar-hero-img--active" : "");
          return <div key={pic.image} style={{"backgroundImage": `url(${pic.image})`}} className={classes} ></div>
          // return <img key={pic.image} src={pic.image} className={classes} alt="Hero gallery item"></img>
          // return <Image key={pic.image.id} fluid={pic.image.childImageSharp.fluid} className={classes}></Image>
        })
      }
    <TopMenu color="white" lang={page.language} languages={pageContext.languages} slug={slug} translation={pageContext.translation}></TopMenu>
    <Link to={pageContext.translation.nav[1].url} className="btn tar-hero-cta">
      <button className="tar-button tar-button--3">
        {pageContext.translation.nav[1].title}
      </button>
    </Link>
    </section>
  )
}

export const indexQuery = graphql`
  query IndexById($id: String!, $language: String!) {
    page: mdx(
      id: { eq: $id },
      frontmatter: {
        type: { eq: 0 }
        templateKey: { eq: "templates/index" }
        language: { eq: $language }
      }
    ) {
      fields {
        slug
      }
      frontmatter {
        language
        title
        description
        content
        pictures {
          image
        }
      }
    }
  }
`
export default IndexPageTemplate
